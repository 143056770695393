import React from 'react';
import SEO from 'layout/SEO';
import { Link } from 'gatsby';
import styled from 'styled-components';
import { Container } from 'react-bootstrap';
import { StyledH1, StyledH2, StyledContent as Content } from 'components/Global.styles';

const Styled404 = styled.main`
  text-align: center;
  display: grid;
  place-items: center;
  height: 100vh;
`;

const StyledLink = styled(Link)`
  cursor: pointer;
  color: ${({ theme }) => theme.colors.heading};
  background-color: ${({ theme }) => theme.colors.defaultBackground};
  box-shadow: 0 0 16px rgba(213, 180, 67, 0.35);
  font-family: 'WindsorD-Reg', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu,
    Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-weight: 300;
  text-align: center;
  padding: 15px 24px 10px;
  border: none;
  border-radius: 10px;
  font-size: 2.6rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-top: 40px;
  ${({ theme }) => theme.mq.mobile} {
    padding: 12px 20px;
  }

  &:hover {
    color: ${({ theme }) => theme.colors.heading};
    text-decoration: none;
  }
`;

const NotFoundPage = ({ location }) => (
  <>
    <SEO title="404 NotFound" location={location} />
    <Styled404>
      <Container>
        <StyledH1>Error 404</StyledH1>
        <StyledH2>Page not found</StyledH2>
        <Content>
          <StyledLink to="/">Go back</StyledLink>
        </Content>
      </Container>
    </Styled404>
  </>
);

export default NotFoundPage;
